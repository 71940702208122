import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {Root} from './styles';
import OverviewDay from '../OverviewDay';
import {TitleRow} from '../commonStyles';

class OverviewGrid extends React.Component {
  static propTypes = {
    overviewEvents: PropTypes.array.isRequired
  };

  render() {
    const {overviewEvents} = this.props;

    return (
      <Root>
        <TitleRow />
        {_.map(overviewEvents, (item, i) => (
          <OverviewDay data={item} key={i} />
        ))}
      </Root>
    );
  }
}

const getYear = state => moment(state.date).year();
const getOverviewEvents = state => state.overviewEvents;

const getFilteredOverviewEvents = createSelector(
  [getYear, getOverviewEvents],
  (year, overviewEvents) => {
    return _.filter(overviewEvents, event => {
      return moment(event.date).year() === year;
    });
  }
);

const mapStateToProps = state => {
  return {
    overviewEvents: getFilteredOverviewEvents(state)
  };
};

export default connect(mapStateToProps)(OverviewGrid);
