import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {Root, Date, EventsContainer} from './styles';
import OverviewEvent from '../OverviewEvent';

class OverviewDay extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  render() {
    const {date, events} = this.props.data;
    return (
      <Root>
        <Date>{moment(date).format('DD.MM')}</Date>
        <EventsContainer>
          {_.map(events, (event, i) => (
            <OverviewEvent event={event} key={i} />
          ))}
        </EventsContainer>
      </Root>
    );
  }
}

export default OverviewDay;
