import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Root} from './styles';
import {eventPopupUpdateAC} from '../../shared/actions';

class OverviewEvent extends React.Component {
  static propTypes = {
    event: PropTypes.object.isRequired,
    openPopup: PropTypes.func.isRequired
  };

  handleClick = e => {
    const {openPopup, event} = this.props;
    openPopup({
      isOpen: true,
      x: e.pageX,
      y: e.pageY,
      color: _.get(event, 'color'),
      description: event.description
    });
    e.stopPropagation();
  };

  render() {
    const {event} = this.props;

    return (
      <Root bgColor={_.get(event, 'color')} onClick={this.handleClick}>
        {event.title}
      </Root>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openPopup: payload => dispatch(eventPopupUpdateAC(payload))
  };
};

export default connect(null, mapDispatchToProps)(OverviewEvent);
