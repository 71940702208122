import styled from 'styled-components';
import {
  OVERVIEW_EVENT_HEIGHT,
  GAP_XXS,
  HOUR_COLUMN_WIDTH
} from '../../shared/constants';

export const Root = styled.div`
  display: flex;
  min-height: ${OVERVIEW_EVENT_HEIGHT}px;
`;

export const Date = styled.div`
  padding-top: ${GAP_XXS}px;
  width: ${HOUR_COLUMN_WIDTH}px;
  text-align: center;
`;

export const EventsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
