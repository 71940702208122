import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import OverviewGrid from '../components/OverviewGrid';
import EventPopup from '../components/EventPopup';
import Seo from '../components/Seo';
import {handleAnalytics} from '../shared/analytics';
import {TRACK_NAVIGATION_OVERVIEW} from '../shared/constants';

class OverviewPage extends React.Component {
  componentDidMount() {
    handleAnalytics({event: TRACK_NAVIGATION_OVERVIEW});
  }

  render() {
    return (
      <Layout>
        <Seo />
        <Header interval="year" />
        <OverviewGrid />
        <Footer />
        <EventPopup />
      </Layout>
    );
  }
}

export default OverviewPage;
