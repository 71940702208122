import {readableColor} from 'polished';
import styled from 'styled-components';
import {DEFAULT_EVENT_TEXT_COLOR, GAP_XXS} from '../../shared/constants';

export const Root = styled.div`
  flex-grow: 1;
  padding: ${GAP_XXS}px;
  background: ${props => props.bgColor};
  color: ${({bgColor}) =>
    bgColor ? readableColor(bgColor) : DEFAULT_EVENT_TEXT_COLOR};
`;
